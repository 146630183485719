import { FormItemRule } from 'element-plus'
import { Arrayable } from 'element-plus/es/utils'
import { isEmpty } from 'lodash-es'

const { t } = useI18n()

interface RegItem {
  reg: RegExp
  message: string
}

type RegObj = Record<string, RegItem>

/**
 * 常用的正则表达式
 */
export const regExps: RegObj = {
  // //匹配数字
  number: {
    reg: /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/,
    message: '只能输入数字'
  },
  // 匹配正整数（正整数是为大于0的整数）
  positiveInteger: {
    reg: new RegExp('^[0-9]*[1-9][0-9]*$'), // /^[0-9]*[1-9][0-9]*$/g,
    message: '只能输入正整数'
  },
  //验证非负整数（正整数 + 0）,并且不能以0开头，如01则验证不通过
  positiveIntegerAndZero: {
    reg: /^(0|[1-9]\d*)$/,
    message: '只能输入0和正整数'
  },

  //验证只能输入大于0的数，且最多两位小数
  positiveIntegerWithTwoDigits: {
    reg: /^[1-9]\d*(\.\d{1,2})?$|^0\.\d{1,2}$/,
    message: '只能输入大于0的数，且最多两位小数'
  },

  //验证0和大于0的数，且最多两位小数
  positiveIntegerAndZeorWithTwoDigits: {
    reg: /^(0|[1-9]\d*)(\.\d{1,2})?$|^0\.\d{1,2}$/,
    message: '只能输入0或者大于0的数，且最多两位小数'
  },

  // 验证颜色
  color: {
    reg: /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/,
    message: '颜色值错误'
  },
  // 手机号
  mobile: {
    reg: /^1[3-9]\d{9}$/g,
    message: '手机号格式错误'
  }
}

// 必填项
export const required = {
  required: true,
  message: t('common.required')
}

/**
 * 必须验证。 与 required 的是可以传一个提示文字
 * @param message 提示文字
 * @returns
 */
export const requiredRule = (message?: string): FormItemRule => {
  return {
    required: true,
    message: message || t('common.required')
  }
}

/**
 * 验证字符串长度
 * @param min 最小长度
 * @param max 最大长度
 * @param message 提示文字
 * @returns
 */
export const lengthRule = (min: number, max: number, message?: string): FormItemRule => {
  return {
    min,
    max,
    message: message || `长度必须在${min}~${max}之间`
  }
}

/**
 * 验证字URL
 * @param message 提示文字
 * @returns
 */
export const urlRule = (message?: string, trigger?: Arrayable<string>): FormItemRule => {
  return {
    type: 'url',
    trigger,
    message: message || `URL 格式不正确`
  }
}

/**
 * 正整数(不包括0)的校验规则
 * @param trigger
 * @returns
 */
export const positiveIntegerRule = (trigger?: Arrayable<string>): FormItemRule => {
  return {
    validator: isPositiveInteger,
    trigger
  }
}

/**
 * （正整数 + 0）校验规则
 * @param trigger
 * @returns
 */
export const positiveIntegerAndZeroRule = (trigger?: Arrayable<string>): FormItemRule => {
  return {
    validator: isPositiveIntegerAndZero,
    trigger
  }
}

/**
 * 只能输入0和大于0的数，且最多两位小数 校验规则
 * @param trigger
 * @returns
 */
export const positiveIntegerAndZeroWithTwoDigitsRule = (
  trigger?: Arrayable<string>
): FormItemRule => {
  return {
    pattern: regExps.positiveIntegerAndZeorWithTwoDigits.reg,
    message: regExps.positiveIntegerAndZeorWithTwoDigits.message,
    trigger
  }
}

/**
 * 只能输入大于0的数，且最多两位小数 校验规则
 * @param trigger
 * @returns
 */
export const positiveIntegerWithTwoDigitsRule = (trigger?: Arrayable<string>): FormItemRule => {
  return {
    pattern: regExps.positiveIntegerWithTwoDigits.reg,
    message: regExps.positiveIntegerWithTwoDigits.message,
    trigger
  }
}

/**
 * 验证手机号
 * @param trigger
 * @returns
 */
export const mobileRule = (trigger?: Arrayable<string>): FormItemRule => {
  return {
    pattern: regExps.mobile.reg,
    message: regExps.mobile.message,
    trigger
  }
}

/**
 * 验证颜色值
 * @param trigger
 * @returns
 */
export const colorRule = (trigger?: Arrayable<string>): FormItemRule => {
  return {
    pattern: regExps.color.reg,
    message: regExps.color.message,
    trigger
  }
}

////////////// is ////////////

/**
 * 非0的正整数
 * @param rule
 * @param value
 * @param callback
 * @returns
 */
export const isPositiveInteger = (rule, value, callback) => {
  checkValue(value, callback)
  valide(rule, value, callback, regExps.positiveInteger)

  callback()
}

/**
 * （正整数 + 0）校验
 * @param rule
 * @param value
 * @param callback
 */
export const isPositiveIntegerAndZero = (rule, value, callback) => {
  checkValue(value, callback)
  valide(rule, value, callback, regExps.positiveIntegerAndZero)

  callback()
}

/**
 * 验证 只能输入大于0的整数，且最多两位小数
 * @param rule
 * @param value
 * @param callback
 * @returns
 */
export const isPositiveIntegerWithTwoDigits = (rule, value, callback) => {
  checkValue(value, callback)
  valide(rule, value, callback, regExps.positiveIntegerWithTwoDigits)

  callback()
}

/**
 * 验证 颜色值
 * @param rule
 * @param value
 * @param callback
 * @returns
 */
export const isColor = (rule, value, callback) => {
  checkValue(value, callback)
  valide(rule, value, callback, regExps.color)

  callback()
}

const checkValue = (value, callback) => {
  if (isEmpty(value)) {
    callback()
    return
  }
}
const valide = (rule, value, callback, regItem: RegItem) => {
  if (!regItem.reg.test(value)) {
    callback(new Error(rule.message || regItem.message))
    return
  }
}

// export const isEnglish = /^[A-Za-z]+$/g
// isEnglish.name = '匹配由26个英文字母组成的字符串'

// export const isLowercaseEnglish = /^[a-z]+$/g
// isLowercaseEnglish.name = '匹配由26个英文字母的小写组成的字符串'

// export const isUppercaseEnglish = /^[A-Z]+$/g
// isUppercaseEnglish.name = '匹配由26个英文字母的大写组成的字符串'

// export const isEnglishAndNumber = /^[A-Za-z0-9]+$/g
// isEnglishAndNumber.name = '匹配由数字和26个英文字母组成的字符串'

// export const isEnglishAndNumberAndUnderline = /^\w+$/g
// isEnglishAndNumberAndUnderline.name = '匹配由数字、26个英文字母或者下划线组成的字符串'

// export const isChinese = /^[\u4e00-\u9fa5]{0,}$/g
// isChinese.name = '匹配中文'

// export const isIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/g
// isIdCard.name = '匹配身份证（一代&二代）'

// export const isFirstGenerationIDCard = /(^\d{15}$)/g
// isFirstGenerationIDCard.name = '匹配一代身份证'

// export const isSecondGenerationIDCard = /(^\d{18}$)|(^\d{17}(\d|X|x)$)/g
// isSecondGenerationIDCard.name = '匹配二代身份证'

// export const isUrl = /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/g
// isUrl.name = '匹配URL'

// export const isIP = /^((25[0-5]|2[0-4]\d|[01]?\d\d?)($|(?!\.$)\.)){4}$/g
// isIP.name = '匹配IP'

// export const isDate = /^(\d{4})-(\d{2})-(\d{2})$/g
// isDate.name = '匹配日期'

// export const isTime = /^([01]\d|2[0-3])(:[0-5]\d){1,2}$/g
// isTime.name = '匹配时间'

// export const isDateTime = /^(\d{4})-(\d{2})-(\d{2})\s([01]\d|2[0-3])(:[0-5]\d){1,2}$/g
// isDateTime.name = '匹配日期时间'

// export const isQQ = /^[1-9][0-9]{4,9}$/g
// isQQ.name = '匹配QQ'

// export const isWeChat = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/g
// isWeChat.name = '匹配微信'

// export const isPostalCode = /[1-9]\d{5}(?!\d)/g
// isPostalCode.name = '匹配邮编'

// export const isMacAddress = /^([A-Fa-f0-9]{2}:){5}[A-Fa-f0-9]{2}$/g
// isMacAddress.name = '匹配MAC地址'

// export const isIPV4 = /^((25[0-5]|2[0-4]\d|[01]?\d\d?)($|(?!\.$)\.)){4}$/g
// isIPV4.name = '匹配IPV4地址'

// export const isIPV6 = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/g
// isIPV6.name = '匹配IPV6地址'

// export const isBase64 = /[^A-Za-z0-9\+\/\=]/g
// isBase64.name = '匹配Base64'

// export const isPasswordStrong = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/g
// isPasswordStrong.name = '匹配强密码'

// export const isPasswordMedium = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,16}$/g
// isPasswordMedium.name = '匹配中等密码'

// export const isPasswordSimple = /^[a-zA-Z0-9_-]{6,16}$/g
// isPasswordSimple.name = '匹配简单密码'

// export const isCarNumber = /^[\u4e00-\u9fa5]{1}[A-Z]{1}[A-Z_0-9]{5}$/g
// isCarNumber.name = '匹配车牌号（简单）'

// export const isLicensePlateNumber =
//   /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领 A-Z]{1}[A-HJ-NP-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/g
// isLicensePlateNumber.name = '匹配车牌号（严格）'

// export const isStockCode = /^(s[hz]|S[HZ])(000[\d]{3}|002[\d]{3}|300[\d]{3}|600[\d]{3}|60[\d]{4})$/g
// isStockCode.name = '匹配股票代码'

// export const isBankCard = /^([1-9]{1})(\d{14}|\d{18})$/g
// isBankCard.name = '匹配银行卡'

// export const is126Email = /((^([a-zA-Z]))(\w){5,17})@126.com$/g
// is126Email.name = '匹配126邮箱'

// export const is163Email = /((^([a-zA-Z]))(\w){5,17})@163.com$/g
// is163Email.name = '匹配163邮箱'

// export const isGmailEmail = /((^([a-zA-Z]))(\w){5,17})@gmail.com$/g
// isGmailEmail.name = '匹配Gmail邮箱'

// export const isQQEmail = /((^([a-zA-Z]))(\w){5,17})@qq.com$/g
// isQQEmail.name = '匹配QQ邮箱'

// export const isSinaEmail = /((^([a-zA-Z]))(\w){5,17})@sina.com$/g
// isSinaEmail.name = '匹配新浪邮箱'

// export const isSohuEmail = /((^([a-zA-Z]))(\w){5,17})@sohu.com$/g
// isSohuEmail.name = '匹配搜狐邮箱'

// export const isYahooEmail = /((^([a-zA-Z]))(\w){5,17})@yahoo.com$/g
// isYahooEmail.name = '匹配雅虎邮箱'

// export const isOutlookEmail = /((^([a-zA-Z]))(\w){5,17})@outlook.com$/g
// isOutlookEmail.name = '匹配Outlook邮箱'

// export const isHotmailEmail = /((^([a-zA-Z]))(\w){5,17})@hotmail.com$/g
// isHotmailEmail.name = '匹配Hotmail邮箱'

// export const isEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+$/g
// isEmail.name = '匹配邮箱'
