import {
  createDictApi,
  createDictValueApi,
  deleteDictApi,
  deleteDictValueApi,
  DictValueVO,
  DictVO,
  getAllDictValueApi,
  updateDictApi,
  updateDictValueApi
} from '@/api/system/dict'
import { CACHE_KEY, useCache } from '@/hooks/web/useCache'
import { cloneDeep, isEmpty, pick } from 'lodash-es'
import { defineStore } from 'pinia'
import { store } from '../index'
const { wsCache } = useCache()

export interface DictState {
  dictMap: Map<string, DictValueVO[]>
  inited: boolean // 是否已经初始化了
}

export const useDictStore = defineStore('dict', {
  state: (): DictState => ({
    dictMap: new Map<string, DictValueVO[]>(),
    inited: false
  }),
  getters: {
    getDictMap(): Recordable {
      const dictMap = wsCache.get(CACHE_KEY.DICT_CACHE)
      if (dictMap) {
        this.dictMap = dictMap
      }
      return this.dictMap
    },
    getInited(): boolean {
      this.inited = wsCache.get(CACHE_KEY.IS_SET_DICT_CACHE)
      return this.inited
    }
  },
  actions: {
    /**
     * 通过唯一标识获取自定义
     * @param key 字典唯一标识
     * @returns
     */
    getDictByKey(key: string): DictValueVO[] {
      return this.dictMap[key]
    },

    /**
     * 保存字典
     * @param dict  字典对象
     */
    saveDict(dict: DictVO) {
      return new Promise<DictVO>((resolve, reject) => {
        createDictApi(dict)
          .then((id) => {
            dict.id = id
            this.dictMap[dict.key] = []

            this.removeAllCache()
            this.saveCache()
            resolve(dict)
          })
          .catch((err) => reject(err))
      })
    },

    /**
     * 更新字典
     * @param dict  字典对象
     */
    updateDict(dict: DictVO) {
      const data = cloneDeep(dict) as any
      delete data.key

      return new Promise<DictVO>((resolve, reject) => {
        updateDictApi(data)
          .then(() => {
            resolve(dict)
          })
          .catch((err) => reject(err))
      })
    },

    /**
     * 删除字典
     * @param dict 字典
     * @param deleteValues 是否删除与之关联的字典值
     * @returns
     */
    deleteDict(dict: DictVO, deleteValues: boolean) {
      return new Promise<void>((resolve, reject) => {
        deleteDictApi(dict.id!, deleteValues)
          .then(() => {
            pick(this.dictMap, [dict.key])
            this.removeAllCache()
            this.saveCache()

            resolve()
          })
          .catch((err) => reject(err))
      })
    },

    /**
     * 新增字典值
     * @param key  字典唯一标识
     * @param dictValue  字典值
     */
    saveDictValue(key: string, dictValue: DictValueVO) {
      return new Promise<DictValueVO>((resolve, reject) => {
        createDictValueApi(dictValue)
          .then((id) => {
            dictValue.id = id

            const dictList = this.getDictByKey(key) || []

            dictList.push(dictValue)
            // 重新设置缓存
            this.dictMap[key] = dictList
            this.removeAllCache()
            this.saveCache()

            resolve(dictValue)
          })
          .catch((err) => reject(err))
      })
    },

    /**
     *更新字典值
     * @param key  字典唯一标识
     * @param dictValue  字典值
     */
    updateDictValue(key: string, dictValue: DictValueVO) {
      return new Promise<DictValueVO>((resolve, reject) => {
        updateDictValueApi(dictValue)
          .then(() => {
            const dictList = this.getDictByKey(key) || []

            dictList.forEach((item, index) => {
              if (item.id === dictValue.id) {
                dictList[index] = dictValue
              }
            })
            this.dictMap[key] = dictList
            this.removeAllCache()
            this.saveCache()

            resolve(dictValue)
          })
          .catch((err) => reject(err))
      })
    },

    /**
     *删除字典值
     * @param key  字典唯一标识
     * @param dictValue  字典值
     */
    deleteDictValue(key: string, dictValue: DictValueVO) {
      return new Promise<DictValueVO>((resolve, reject) => {
        deleteDictValueApi(dictValue.id!)
          .then(() => {
            const dictList = this.getDictByKey(key) || []

            const index = dictList.findIndex((item) => item.id === dictValue.id)
            dictList.splice(index, 1)

            if (!isEmpty(dictList)) {
              this.dictMap[key] = dictList
            } else {
              // if (this.dictMap.has(key)) this.dictMap.delete(key)

              pick(this.dictMap, [key])
            }

            this.removeAllCache()
            this.saveCache()

            resolve(dictValue)
          })
          .catch((err) => reject(err))
      })
    },

    /**
     * 设置字典Map
     */
    async initDictMap() {
      const dictDataMap = await getAllDictValueApi()
      this.dictMap = dictDataMap
      this.saveCache()
    },

    /**
     * 重置字典（删除缓存）
     */
    async resetDict() {
      this.removeAllCache()
      const dictDataMap = await getAllDictValueApi()

      this.dictMap = dictDataMap
      this.inited = true
      this.saveCache()
    },

    saveCache() {
      wsCache.set(CACHE_KEY.DICT_CACHE, this.dictMap)
      wsCache.set(CACHE_KEY.IS_SET_DICT_CACHE, true)
      this.inited = true
    },

    removeAllCache() {
      wsCache.delete(CACHE_KEY.DICT_CACHE)
    }
  }
})

export const useDictStoreWithOut = () => {
  return useDictStore(store)
}
