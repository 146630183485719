import { defineStore } from 'pinia'
import { store } from '../index'
import { cloneDeep } from 'lodash-es'
import {
  generateRoute,
  flatMultiLevelRoutes,
  reNameRouteNames,
  mergeRoutes
} from '@/utils/routerHelper'
import { CACHE_KEY, useCache } from '@/hooks/web/useCache'

const { wsCache } = useCache()

export interface RouteState {
  routeInited: boolean
  routers: AppRouteRecordRaw[]
  addRouters: AppRouteRecordRaw[]
  menuTabRouters: AppRouteRecordRaw[]
}

export const useRouteStore = defineStore('route', {
  state: (): RouteState => ({
    routeInited: false,
    routers: [],
    addRouters: [],
    menuTabRouters: []
  }),
  getters: {
    getRouteInited(): boolean {
      return this.routeInited
    },
    getRouters(): AppRouteRecordRaw[] {
      return this.routers
    },
    getAddRouters(): AppRouteRecordRaw[] {
      return flatMultiLevelRoutes(cloneDeep(this.addRouters))
    },
    getMenuTabRouters(): AppRouteRecordRaw[] {
      return this.menuTabRouters
    }
  },
  actions: {
    generateRoutes(): Promise<unknown> {
      return new Promise<void>((resolve) => {
        let res: AppCustomRouteRecordRaw[] = []
        if (wsCache.get(CACHE_KEY.ROLE_ROUTERS)) {
          res = wsCache.get(CACHE_KEY.ROLE_ROUTERS) as AppCustomRouteRecordRaw[]
        }

        const routerMap: AppRouteRecordRaw[] = generateRoute(res as AppCustomRouteRecordRaw[])
        // 对路由名称进行重命名，否则相同的路由名称会报错。
        reNameRouteNames(routerMap)

        // 将本地路由和后端配置的路由进行合并
        const finalRouters = mergeRoutes(routerMap)

        // 动态路由，404一定要放到最后面
        this.addRouters = finalRouters.concat([
          {
            path: '/:path(.*)*',
            redirect: '/404',
            name: 'noFound',
            meta: {
              hidden: true,
              breadcrumb: false
            }
          }
        ])
        // 渲染菜单的所有路由
        // this.routers = cloneDeep(remainingRouter).concat(routerMap)
        this.routers = finalRouters
        this.routeInited = true
        resolve()
      })
    },
    setMenuTabRouters(routers: AppRouteRecordRaw[]): void {
      this.menuTabRouters = routers
    },

    /**
     * 重新构建路由
     */
    reGenerateRoutes() {
      wsCache.delete(CACHE_KEY.ROLE_ROUTERS)
      this.generateRoutes()
    }
  }
})

export const useRouteStoreWithOut = () => {
  return useRouteStore(store)
}
