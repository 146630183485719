const Layout = () => import('@/layout/layout.vue')

const { t } = useI18n()
/**
 * redirect: noredirect        当设置 noredirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'          设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
      hidden: true              当设置 true 的时候该路由不会再侧边栏出现 如404，login等页面(默认 false)

      alwaysShow: true          当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式，
      只有一个时，会将那个子路由当做根路由显示在侧边栏，
      若你想不管路由下面的 children 声明的个数都显示你的根路由，
      你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，
      一直显示根路由(默认 false)

      title: 'title'            设置该路由在侧边栏和面包屑中展示的名字

      icon: 'svg-name'          设置该路由的图标

      noCache: true             如果设置为true，则不会被 <keep-alive> 缓存(默认 false)

      breadcrumb: false         如果设置为false，则不会在breadcrumb面包屑中显示(默认 true)

      affix: true               如果设置为true，则会一直固定在tag项中(默认 false)

      noTagsView: true          如果设置为true，则不会出现在tag中(默认 false)

      activeMenu: '/dashboard'  显示高亮的路由路径

      followAuth: '/dashboard'  跟随哪个路由进行权限过滤

      canTo: true               设置为true即使hidden为true，也依然可以进行路由跳转(默认 false)
 }
 **/
export const remainingRouter: AppRouteRecordRaw[] = [
  {
    path: '/', // 首页
    component: Layout,
    redirect: '/index',
    name: 'home',
    meta: {},
    children: [
      {
        path: 'index',
        component: () => import('@/views/index.vue'),
        name: 'index',
        meta: {
          title: t('router.home'),
          icon: 'ep:home-filled',
          noCache: false,
          affix: true
        }
      }
    ]
  },

  {
    path: '/mall/product-spu', // 商品中心
    component: Layout,
    name: 'mall-product-spu',
    meta: {
      hidden: true
    },
    children: [
      {
        path: 'create',
        component: () => import('@/views/mall/product/spu/publish/_id.vue'),
        name: 'mall-product-spu-add',
        meta: {
          noCache: true,
          hidden: true,
          canTo: true,
          icon: 'ep:edit',
          title: '商品添加',
          activeMenu: '/mall/product/spu'
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import('@/views/mall/product/spu/publish/_id.vue'),
        name: 'mall-product-spu-edit',
        meta: {
          noCache: true,
          hidden: true,
          canTo: true,
          icon: 'ep:edit',
          title: '商品编辑',
          activeMenu: '/mall/product/spu'
        }
      },
      {
        path: 'detail/:id(\\d+)',
        component: () => import('@/views/mall/product/spu/detail/_id.vue'),
        name: 'mall-product-spu-detail',
        meta: {
          noCache: true,
          hidden: true,
          canTo: true,
          icon: 'ep:view',
          title: '商品详情',
          activeMenu: '/mall/product/spu'
        }
      }
    ]
  },
  {
    path: '/mall/trade', // 交易中心
    component: Layout,
    name: 'mall-trade',
    meta: {
      hidden: true
    },
    children: [
      {
        path: 'detail/:id(\\d+)',
        component: () => import('@/views/mall/trade/order/detail/_id.vue'),
        name: 'mall-trade-order-detail',
        meta: {
          title: '订单详情',
          icon: 'ep:view',
          noCache: true,
          hidden: true,
          activeMenu: '/mall/trade/order'
        }
      }
    ]
  },

  {
    path: '/mall/delivery/template', // 运费模板
    component: Layout,
    name: 'mall-delivery-template',
    meta: {
      hidden: true
    },
    children: [
      {
        path: 'create',
        component: () => import('@/views/mall/trade/delivery/template/edit/_id.vue'),
        name: 'mall-trade-delivery-create',
        meta: {
          title: '添加运费模板',
          noCache: true,
          hidden: true,
          canTo: true,
          icon: 'ep:edit',
          activeMenu: '/mall/trade/delivery/template'
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import('@/views/mall/trade/delivery/template/edit/_id.vue'),
        name: 'mall-trade-delivery-edit',
        meta: {
          noCache: true,
          hidden: true,
          canTo: true,
          icon: 'ep:edit',
          title: '编辑运费模板',
          activeMenu: '/mall/trade/delivery/template'
        }
      }
    ]
  },

  {
    path: '/mall/diy', // 店铺装修
    name: 'mall-diy',
    meta: { hidden: true },
    component: Layout,
    children: [
      {
        path: 'page/:id',
        name: 'mall-diy-page',
        meta: {
          title: '页面装修',
          icon: 'ep:brush',
          noCache: true,
          hidden: true,
          activeMenu: '/mall/diy'
        },
        component: () => import('@/views/mall/diy/page/index.vue')
      }
    ]
  },
  {
    path: '/mall/member',
    component: Layout,
    name: 'mall-member',
    meta: { hidden: true },
    children: [
      {
        path: 'detail/:id',
        name: 'mall-member-detail',
        meta: {
          title: '会员详情',
          icon: 'ep:user',
          noCache: true,
          hidden: true,
          activeMenu: '/mall/member'
        },
        component: () => import('@/views/member/user/detail/_id.vue')
      }
    ]
  },

  {
    path: '/system/user',
    component: Layout,
    name: 'system-user',
    meta: {
      hidden: true
    },
    children: [
      {
        path: 'profile',
        component: () => import('@/views/profile/index.vue'),
        name: 'system-user-profile',
        meta: {
          canTo: true,
          hidden: true,
          noTagsView: false,
          icon: 'ep:user',
          title: t('common.profile')
        }
      }
    ]
  }
]

/**
 * 固定页面
 */
export const staticRouter: AppRouteRecordRaw[] = [
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    name: 'login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },

  {
    path: '/403',
    component: () => import('@/views/error/403.vue'),
    name: 'noAccess',
    meta: {
      hidden: true,
      title: '403',
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/error/404.vue'),
    name: 'noFound',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  },
  {
    path: '/500',
    component: () => import('@/views/error/500.vue'),
    name: 'error',
    meta: {
      hidden: true,
      title: '500',
      noTagsView: true
    }
  }
]
