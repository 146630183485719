import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'

import { useNProgress } from '@/hooks/web/useNProgress'
import { usePageLoading } from '@/hooks/web/usePageLoading'
import { useTitle } from '@/hooks/web/useTitle'
import { useRouteStoreWithOut } from '@/store/modules/route'
import { getAccessToken } from '@/utils/auth'
import { parseURL } from '@/utils/routerHelper'
import { staticRouter } from './modules/remaining'

const router = createRouter({
  history: createWebHashHistory(), // createWebHashHistory URL带#，createWebHistory URL不带#
  strict: true,
  routes: staticRouter as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

const { start, done } = useNProgress()
const { loadStart, loadDone } = usePageLoading()

const routeStore = useRouteStoreWithOut()

// 路由不重定向白名单
const whiteList = ['/login']

// 路由加载前
router.beforeEach(async (to, from, next) => {
  start()
  loadStart()
  if (getAccessToken()) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      if (!routeStore.getRouteInited) {
        // 后端过滤菜单
        await routeStore.generateRoutes()

        // 动态添加可访问路由表,每次刷新页面都需要
        routeStore.getAddRouters.forEach((route) => {
          router.addRoute(route as unknown as RouteRecordRaw)
        })

        const redirectPath = from.query.redirect || to.path
        // 修复跳转时不带参数的问题
        const redirect = decodeURIComponent(redirectPath as string)
        const { paramsObject: query } = parseURL(redirect)
        const nextData = to.path === redirect ? { ...to, replace: true } : { path: redirect, query }

        next(nextData)
      } else {
        next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
    }
  }
})

router.afterEach((to) => {
  useTitle(to?.meta?.title as string)
  done() // 结束Progress
  loadDone()
})

export const resetRouter = (): void => {
  const resetWhiteNameList = ['redirect', 'login', 'NoFind', 'root']

  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !resetWhiteNameList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

// export { router as default, localRawRoutes, localRoutes }

export default router
